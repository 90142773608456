import React from "react";

const NavBar = () => {
  return (
    <div className="navigation">
      <a href="/" className="btn">
        Home
      </a>
      <a href="#about" className="btn">
        About Me
      </a>
      <a href="#projects" className="btn">
        Projects
      </a>
      <a href="#blogs" className="btn">
        Blogs
      </a>
      <a href="#contact" className="btn">
        Contact
      </a>
    </div>
  );
};

export default NavBar;
